/* Creates a Treeview with Productgroups or simular data for the vuetify Datatree Component <v-treeview></v-treeview>
https://vuetifyjs.com/en/components/treeview/
*/

import { nameLocale } from './various'

/** this goes directli into the <treeview/> component */
export class TreeItem {
  id!: number
  title!: string
  children: TreeItem[] | undefined
  parentId!: number | null | undefined //rootelement has no parent
}

// this is like the database productGroupt object
export class FlatItem {
  id!: number
  parentId!: number | null | undefined //rootelement has no parent
  title!: string
}

export function createDataTree(parentId: number | null, items: FlatItem[]): TreeItem[] | undefined {
  const thisItems: FlatItem[] = items.filter((itemX) => itemX.parentId == parentId)
  if (typeof thisItems == 'undefined' || thisItems.length == 0) return undefined // this stopps the recursion

  const treeItems: TreeItem[] = []
  thisItems.forEach((itemX) => {
    treeItems.push({
      id: itemX.id,
      title: itemX.title,
      children: createDataTree(itemX.id, items),
      parentId: parentId
    })
  })
  return treeItems
}

export function toFlatItems(items: any[], isoLangKey: string): FlatItem[] {
  const flatItems: FlatItem[] = []

  items.forEach((element) => {
    flatItems.push(productGroupToFlatItem(element, isoLangKey))
  })
  return flatItems
}

export function productGroupToFlatItem(item: any, isoLangKey: string): FlatItem {
  return {
    id: item.id,
    parentId: item.parentId,
    title: item[nameLocale(isoLangKey)] + ' (' + item.ident + ')'
  }
}
