<!-- 

use me like this

<ProductGroupTreeView
          v-model= "model.productGroupMain.id"
          rootId ="1000"
          title="MainGroup"
          />

-->
<template>
  <div>
    {{ title }}: {{ selectedName }}
    <v-treeview
      ref="treeview"
      :items="productGroupTreeItems"
      item-value="id"
      :activatable="editable"
      :active="activeItem"
      open-on-click
      @update:activated="setProductGroup"
      return-object
      density="compact"
    />
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import { createDataTree, toFlatItems, FlatItem } from '@/utils/Treeview'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productGroupTreeview',
  props: {
    rootId: Number,
    modelValue: Number | null,
    title: String,
    editable: Boolean
  },
  emits: ['update:modelValue'],
  data() {
    return {
      productGroupsUrl: apiURL + '/productGroups/findAll?',
      productGroup: [],
      productGroupTreeItems: [],
      productGroupFilteredFlatItems: [],
      selectedName: '',
      activeItem: []
    }
  },
  methods: {
    productGroupsFilterbyRootIdent(elements, rootIdent) {
      // todo fix the backend ..
      let rootElement = elements.filter((elX) => elX.ident === rootIdent)[0]
      return elements.filter((elY) => elY.rootId === rootElement.id)
    },
    async loadProductGroups() {
      try {
        const result = await this.axios.get(
          this.productGroupsUrl,
          {
            params: {
              size: 100000,
              rootId: this.rootId,
              term: ''
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          //this is a backend bug .. filter root elements ..
          const productGroupFiltered = this.productGroupsFilterbyRootIdent(result.data.content, this.rootId)
          this.productGroupFilteredFlatItems = toFlatItems(productGroupFiltered, this.$route.params.lang)
          this.productGroupTreeItems = createDataTree(null, this.productGroupFilteredFlatItems, 0)
          this.setTitleById(this.modelValue || null)
        }
      } catch (e) {
        showError(e)
      }
    },
    setProductGroup(inputs: FlatItem[] | null) {
      if (inputs[0]) {
        let id = inputs[0].id
        this.$emit('update:modelValue', id)
        this.setTitleById(id)
      } else {
        this.$emit('update:modelValue', null)
        this.setTitleById(null)
      }
      //this.selectedName = inputs.title
    },
    setTitleById(itemId) {
      this.selectedName = this.productGroupFilteredFlatItems.filter((elX) => elX.id === itemId)[0]?.title
    }
  },
  watch: {
    modelValue: {
      handler(to) {
        if (to) {
          this.setTitleById(to)
        } else {
          this.selectedName = ''
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.loadProductGroups()

      if (this.modelValue) {
        this.setTitleById(this.modelValue)
      } else {
        this.selectedName = ''
      }
    })
  }
})
</script>
<style lang="scss" scoped>
div.v-treeview .v-btn {
  border-color: none;
  border: none;
}
</style>
