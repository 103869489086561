<template>
  <v-row>
    <!-- Fettumwandlungsfaktor (Kessi) -->
    <v-col cols="12" sm="12" md="12">
      <v-select
        :label="$t('product_cheese_details_fat_conversion')"
        :items="fatConversionFactors"
        item-value="name"
        item-title="label"
        v-model="modelLocal.fatConversionFactor"
        :rules="[rules.required]"
        :readonly="readonly()"
        :clearable="!readonly()"
      />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-checkbox id="checkbox-1" v-model="modelLocal.silageWaiver" :label="$t('product_edit_product_silage_waiver')" :readonly="readonly()" />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-checkbox
        id="checkbox-2"
        v-model="modelLocal.milkSilageWaiverSubsidy"
        :label="$t('product_edit_product_silage_waiver_subsidy')"
        :readonly="readonly()"
      />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-checkbox id="checkbox-3" v-model="modelLocal.sumValidation" :label="$t('product_edit_product_sum_control')" :readonly="readonly()" />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-checkbox
        id="checkbox-4"
        v-model="modelLocal.milkCheeseProductionSubsidy"
        :label="$t('product_edit_product_cheese_making_milk_subsidy')"
        :readonly="readonly()"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      apiURL: apiURL + '/products/',
      fatConversionFactors: [],
      rules: {
        required: (value) => !!value || this.$t('form_json_schema_required_field')
      }
    }
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    modelLocal: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    readonly() {
      return this.modelLocal.id > 0
    },
    async loadFatConversionFactors() {
      try {
        const result = await this.axios.get(this.apiURL + 'fatConversionFactors')
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.fatConversionFactors = await this.loadFatConversionFactors()
    })
  }
})
</script>
