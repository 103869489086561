<template>
  <v-row>
    <v-col cols="6" sm="12" md="6">
      <v-select
        :label="$t('product_cheese_details_cheese_firmness')"
        :items="cheeseFirmnessLevels"
        item-value="name"
        :item-title="this.$getLangKey()"
        :rules="[rules.required]"
        v-model="modelLocal.cheeseFirmnessLevel"
        clearable
      />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-select
        :label="$t('product_cheese_details_cheese_fat')"
        :items="cheeseFatLevels"
        item-value="name"
        :item-title="this.$getLangKey()"
        :rules="[rules.required]"
        v-model="modelLocal.cheeseFatLevel"
        clearable
      />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-select
        :label="$t('product_cheese_details_cheese_subsidy')"
        :items="cheeseSubsidyOrders"
        item-value="name"
        :item-title="this.$getLangKey()"
        v-model="modelLocal.cheeseSubsidyOrder"
        :readonly="readonly()"
        :clearable="!readonly()"
      />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-select
        :label="$t('product_cheese_details_silage_waiver_subsidy_orders')"
        :items="silageWaiverSubsidyOrders"
        item-value="value"
        :item-title="this.$getLangKey()"
        v-model="modelLocal.silageWaiverSubsidyOrder"
        :readonly="readonly()"
        :clearable="!readonly()"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL } from '@/main'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      apiURL: apiURL,
      cheeseFirmnessLevels: [],
      cheeseFatLevels: [],
      cheeseSubsidyOrders: [],
      silageWaiverSubsidyOrders: [],
      rules: {
        required: (value) => !!value || this.$t('form_json_schema_required_field')
      }
    }
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    modelLocal: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    readonly() {
      return this.modelLocal.id > 0
    },
    async loadCheeseFirmnessLevels() {
      try {
        const result = await this.axios.get(this.apiURL + '/products/cheeseFirmnessLevels')
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async loadCheeseFatLevels() {
      try {
        const result = await this.axios.get(this.apiURL + '/products/cheeseFatLevels')
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async loadCheeseSubsidyOrders() {
      try {
        const result = await this.axios.get(this.apiURL + '/subsidyOrders?subset=CHEESE')
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async loadSilageWaiverSubsidyOrders() {
      try {
        const result = await this.axios.get(this.apiURL + '/subsidyOrders?subset=SILAGEWAIVER')
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.cheeseFirmnessLevels = await this.loadCheeseFirmnessLevels()
      this.cheeseFatLevels = await this.loadCheeseFatLevels()
      this.cheeseSubsidyOrders = await this.loadCheeseSubsidyOrders()
      this.silageWaiverSubsidyOrders = await this.loadSilageWaiverSubsidyOrders()
    })
  }
})
</script>
