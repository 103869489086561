<template>
  <v-row>
    <!-- min. Gewichtverlust -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="weightLossMin"
        name="weightLossMin"
        v-model="modelLocal.weightLossMin"
        :label="$t('editProductGeneralContent_weightLossMin')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
      />
    </v-col>
    <!-- max. Gewichtverlust -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="weightLossMax"
        name="weightLossMax"
        v-model="modelLocal.weightLossMax"
        :label="$t('editProductGeneralContent_weightLossMax')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
      />
    </v-col>
    <!-- avg. Gewichtverlust -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="weightLossAvg"
        name="weightLossAvg"
        v-model="modelLocal.weightLossAvg"
        :label="$t('editProductGeneralContent_weightLossAvg')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
      />
    </v-col>
    <!-- min. Grüngewicht -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="greenWeightMin"
        name="greenWeightMin"
        v-model="modelLocal.greenWeightMin"
        :label="$t('editProductGeneralContent_greenWeightMin')"
        :precision="2"
        :rules="[$rules.isNumeric]"
      />
    </v-col>
    <!-- max. Grüngewicht -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="greenWeightMax"
        name="greenWeightMax"
        v-model="modelLocal.greenWeightMax"
        :label="$t('editProductGeneralContent_greenWeightMax')"
        :precision="2"
        :rules="[$rules.isNumeric]"
      />
    </v-col>
    <!-- avg. Grüngewicht -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="greenWeightAvg"
        name="greenWeightAvg"
        v-model="modelLocal.greenWeightAvg"
        :label="$t('editProductGeneralContent_greenWeightAvg')"
        :precision="2"
        :rules="[$rules.isNumeric]"
      />
    </v-col>
    <!-- min. Laibgewicht -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="loafWeightMin"
        name="loafWeightMin"
        v-model="modelLocal.loafWeightMin"
        :label="$t('editProductGeneralContent_loafWeightMin')"
        :precision="2"
        :rules="[$rules.isNumeric]"
      />
    </v-col>
    <!-- max. Laibgewicht -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="loafWeightMax"
        name="loafWeightMax"
        v-model="modelLocal.loafWeightMax"
        :label="$t('editProductGeneralContent_loafWeightMax')"
        :precision="2"
        :rules="[$rules.isNumeric]"
      />
    </v-col>
    <!-- avg. Laibgewicht -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="loafWeightAvg"
        name="loafWeightAvg"
        v-model="modelLocal.loafWeightAvg"
        :label="$t('editProductGeneralContent_loafWeightAvg')"
        :precision="2"
        :rules="[$rules.isNumeric]"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import vNumberField from '@/components/v-number-field'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('form_json_schema_required_field')
      }
    }
  },
  props: ['model'],
  components: {
    vNumberField: vNumberField
  },
  computed: {
    modelLocal: {
      get: function () {
        return this.model
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  },
  methods: {
    readonly() {
      return this.modelLocal.id > 0
    }
  },
  mounted() {}
})
</script>
