<template>
  <v-row>
    <!-- min. Fettgehalt -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="fatMin"
        name="fatMin"
        v-model="modelLocal.fatMin"
        :label="$t('editProductGeneralContent_fatMin')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
      />
    </v-col>
    <!-- max. Fettgehalt -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="fatMax"
        name="fatMax"
        v-model="modelLocal.fatMax"
        :label="$t('editProductGeneralContent_fatMax')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
      />
    </v-col>
    <!-- avg. Fettgehalt -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="fatAvg"
        name="fatAvg"
        v-model="modelLocal.fatAvg"
        :label="$t('editProductGeneralContent_fatAvg')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
        :readonly="readonly"
        :disabled="readonly"
      />
    </v-col>
    <!-- min. Proteingehalt -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="proteinMin"
        name="proteinMin"
        v-model="modelLocal.proteinMin"
        :label="$t('editProductGeneralContent_proteinMin')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
      />
    </v-col>
    <!-- max. Proteingehalt -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="proteinMax"
        name="proteinMax"
        v-model="modelLocal.proteinMax"
        :label="$t('editProductGeneralContent_proteinMax')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
      />
    </v-col>
    <!-- avg. Proteingehalt -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="proteinAvg"
        name="proteinAvg"
        v-model="modelLocal.proteinAvg"
        :label="$t('editProductGeneralContent_proteinAvg')"
        :precision="2"
        :rules="[rules.required, $rules.isNumeric]"
        :readonly="readonly"
        :disabled="readonly"
      />
    </v-col>
    <!-- min. Milchäquivalent backend calculated field -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="equivalentMin3"
        name="equivalentMin3"
        v-model="modelLocal.equivalentMin3"
        :label="$t('editProductGeneralContent_equivalentMin3')"
        :precision="3"
        readonly
        disabled
      />
    </v-col>
    <!-- max. Milchäquivalent backend calculated field -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="equivalentMax3"
        name="equivalentMax3"
        v-model="modelLocal.equivalentMax3"
        :label="$t('editProductGeneralContent_equivalentMax3')"
        :precision="3"
        readonly
        disabled
      />
    </v-col>
    <!-- avg. Milchäquivalent backend calculated field -->
    <v-col cols="4" sm="12" md="4">
      <vNumberField
        id="equivalentAvg3"
        name="equivalentAvg3"
        v-model="modelLocal.equivalentAvg3"
        :label="$t('editProductGeneralContent_equivalentAvg3')"
        :precision="3"
        readonly
        disabled
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import vNumberField from '@/components/v-number-field'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('form_json_schema_required_field')
      }
    }
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  components: {
    vNumberField: vNumberField
  },
  computed: {
    modelLocal: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        this.$emit('update:modelValue', value)
      }
    },
    readonly() {
      return this.modelLocal.id > 0
    }
  }
})
</script>
