<template>
  <div>
    <v-row>
      <v-col cols="6" sm="12" md="6">
        <v-text-field type="number" id="code" :label="$t('product_description_code')" v-model="modelLocal.code" :readonly="true" />
      </v-col>
      <v-col cols="6" sm="12" md="6">
        <v-text-field id="nameDe" :label="$t('product_description_nameDe')" v-model="modelLocal.nameDe" :readonly="readonly" :rules="[$rules.required]" />
      </v-col>
      <v-col cols="6" sm="12" md="6">
        <v-text-field id="nameFr" :label="$t('product_description_nameFr')" v-model="modelLocal.nameFr" :readonly="readonly" :rules="[$rules.required]" />
      </v-col>
      <v-col cols="6" sm="12" md="6">
        <v-text-field id="nameIt" :label="$t('product_description_nameIt')" v-model="modelLocal.nameIt" :readonly="readonly" :rules="[$rules.required]" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import BaseMixin from '@/utils/BaseMixin'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {}
  },
  props: {
    model: { type: Object, required: false },
    readonly: { type: Boolean, required: false, default: false }
  },
  computed: {
    modelLocal: {
      get: function () {
        return this.model
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  },
  mixins: [BaseMixin]
})
</script>
